import React from "react"
import Layout from "../../components/layout"
import paypal from '../../assets/pp-thumb.jpg'
import homepage from '../../assets/casestudies/paypal-home.png'
import activity from '../../assets/casestudies/paypal-activity.png'
import ui from '../../assets/casestudies/paypal-mobile.png'
import cfpb from '../../assets/casestudies/paypal-cfpb.png'
import legal from '../../assets/casestudies/paypal-legal.jpg'
import wc from '../../assets/casestudies/paypal-wc.jpg'
import venice from '../../assets/casestudies/paypal-venice.jpg'
import CenteredContent from '../../components/centeredContent'
import FullWidthContent from '../../components/fullWidthContent'

const PayPal = () => (
	<Layout headerType="paypal" headerText='PayPal'>
		<CenteredContent
			image1={paypal}
			header="PayPal"
			body="At PayPal I was involved in many great projects as a Senior Software Engineer and Design Technologist focused primarily on the front end UI."
			rotation="3deg"
		/>
		<FullWidthContent
			image={homepage}
			bgcolor1={'#ddeaff'}
			header="Consumer Home Page Redesign"
			body="PayPal completely redesigned their homepage last year and I got to be involved in the process to build an application that would change the layout based on a users activity and persona. We also tailored the page to be more personalized and p2p centric using various new dynamic customer engagement systems."
			rotation="3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			image1={ui}
			header="Global UI Component Service"
			body="Getting alignment on a UI for a  large company can be difficult as was the case with PayPal's consumer products. As a solution my team built a web service to deliver UI components to our various consumer applications. Before this service the headers and footers across our apps all looked different. I worked with various teams across PayPal to integrate our new serice and once it was rolled out all of our apps had one cohesive look and feel that could be change and experimented on from on central place."
			rotation="-3deg"
		/>
		<FullWidthContent
			image={activity}
			bgcolor1={'#ddeaff'}
			header="PayPal Activity"
			body="A users activity is the most important set of data PayPal has for it's users and making sure it is accessibile on all sceens sizes was important. I optimzied activity for mobile and helped add an additional categorization system to users transactions to help them get a better idea of where they were spending their money."
			rotation="-3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			type="largeMobile"
			image1={venice}
			header="PayPal Credit"
			body="Scheduling payments can be tricky especially when it involves complex components for picking dates. I helped the PayPal Credit team build a datepicker component on web that was intuitive and easy to use. I then worked with product and design to build the same component into an iOS component that our mobile team was able to take and put into the PayPal mobile app ensuring the payment scheduling experience was consisten across platforms."
			rotation="3deg"
		/>
		<FullWidthContent
			image={cfpb}
			bgcolor1={'#ddeaff'}
			header="CFPB Compliance"
			body="As part of a CFPB  compliance initiative, I was the team lead on an effort to A/B test new changes to the PayPal balance experience. By using a new content management system we were able to change page layouts and content on the fly to ensure our users would get the best experience."
			rotation="3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			image1={legal}
			header="PayPal User Agreement"
			body="Legal pages are often boring and verbose so injecting new life into our user agreements pages was a great challenge. I worked with legal, product and design to create a new UI for our legal agreements with a fresh new look and user experience that didn't get in the way of users getting the legal data they wanted. "
			rotation="-3deg"
		/>
		<FullWidthContent
			image={wc}
			bgcolor1={'#ddeaff'}
			header="PayPal Working Capital"
			body="As part of a user research effort into reworking the loan interface for PayPal Working capital I worked on a set of high fidelity prototypes to create new ways for our users to customize their loans."
			rotation="-3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
	</Layout>
)

export default PayPal
